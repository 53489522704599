<template>
    <main
        class="page-content p-2 md:p-2 lg:p-1 xl:p-4 2xl:p-6"
        id="js-page-content"
        role="main"
    >
        <div class="subheader hidden-lg-up">
            <portal to="ariane">
                <MultipleListCreate></MultipleListCreate>
            </portal>
            <portal-target name="ariane"></portal-target>
        </div>

        <div class="moverflow-x-auto">
            <div
                class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg bg-white"
            >
                <TabPosts
                    ref="tabPosts"
                    :isLoadingPosts="isLoadingPosts"
                ></TabPosts>
            </div>
        </div>
    </main>
</template>

<script>
import TabPosts from '@/components/Model/Post/TabPosts'
import Panel from '../../components/UI/Panel'
import { mapActions, mapState } from 'vuex'
import MultipleListCreate from '@/components/UI/MultipleListCreate'

export default {
    name: 'Posts',
    data() {
        return {
            modifStatus: false,
            divCategorie: false,
            roundedCategorie: true,
            divFormulaire: false,
            roundedFormulaire: true,
            divStatus: false,
            roundedStatus: true,
            isLoadingPosts: false
        }
    },
    components: { Panel, TabPosts, MultipleListCreate },
    computed: {
        ...mapState('post', {
            posts: (state) => state.posts,
            postsFilters: (state) => state.postsFilters
        }),
        openFiltreCategories() {
            this.divCategorie = true
            this.roundedCategorie = false
        },
        openFiltreFormulaire() {
            this.divFormulaire = true
            this.roundedFormulaire = false
        },
        openFiltreStatus() {
            this.divStatus = true
            this.roundedStatus = false
        }
    },
    created() {
        let postsPromise = this.getPosts()
        let postsFiltersPromise = this.getPostsFilters()
        let statusesPromise = this.getStatus()
        Promise.all([postsPromise, postsFiltersPromise, statusesPromise]).then(
            () => {
                this.isLoadingPosts = true
            }
        )
    },
    methods: {
        ...mapActions('post', ['getPosts', 'getPostsFilters']),
        ...mapActions('status', ['getStatus'])
    }
}
</script>

<style scoped></style>
