<template>
  <section>
    <PostsFilters @loadpage='loadpage' />

    <div v-if='isLoadingPosts && loading && posts && posts.data'>
      <b-table
        class='postsTable table-striped overflow-x-scroll'
        backend-sorting
        :data='posts.data'
        :striped='true'
        :mobile-cards='true'
        :sticky-header='false'
        :default-sort-direction='defaultSortDirection'
        @sort='onSort'
      >
        <b-table-column
          width='1%'
          sortable
          field='id'
          :label="$t('post.page.tablecolumnlabels.ref')"
          numeric
          v-slot='props'
        >
          {{ props.row.reference }}
        </b-table-column>

        <b-table-column
          field='form.name'
          v-slot='props'
          width='12%'
          :label="$t('post.page.tablecolumnlabels.form')"
        >
          {{ props.row.form.name }}
        </b-table-column>

        <b-table-column
          sortable
          field='status_id'
          v-slot='props'
          width='13%'
          :label="$t('post.page.tablecolumnlabels.status')"
        >
          <ButtonEditStatus :post='props.row' />
        </b-table-column>
        <b-table-column
          sortable
          field='title'
          v-slot='props'
          width='28%'
          :label="$t('post.page.tablecolumnlabels.subject')"
        >
          <router-link
            :to='`/posts/${props.row.id}`'
            class='cursor-pointer align-middle viewPostBtn'
          >
            {{
              props.row.title
                ? props.row.title
                : $t('post.page.tablecolumnlabels.untitled')
            }}
          </router-link>
        </b-table-column>
        <b-table-column
          width='9%'
          sortable
          field='created_at'
          v-slot='props'
          :label="$t('post.page.tablecolumnlabels.date')"
        >
          {{ moment(props.row.created_at).format('L') }}
        </b-table-column>
        <b-table-column
          field='created_by.name'
          v-slot='props'
          :label="$t('post.page.tablecolumnlabels.operator')"
          width='9%'
        >
          <div v-for='(racis, index) in props.row.racis' :key='index'>
                    <span
                      class='flex items-center'
                      v-if='racis.is_responsible == 1'
                    >{{ racis.name }}</span
                    >
          </div>
        </b-table-column>
        <b-table-column
          width='9%'
          sortable
          field='priority'
          :label="$t('post.page.tablecolumnlabels.priority')"
          v-slot='props'
        >
          <ButtonEditPriority :post='props.row'></ButtonEditPriority>
        </b-table-column>
        <b-table-column
          width='9%'
          field='actions'
          :label="$t('post.page.tablecolumnlabels.shares')"
          v-slot='props'
          cell-class='justify-content-between items-center'
        >
          <div>
            <div
              @click.prevent='showPostPreview(props.row)'
              class='cursor-pointer inline-flex items-center px-3 py-2 mx-1 mb-1 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
            >
              <IconSearch class='h-4 w-4' />
            </div>
            <ButtonRemind variant="minimal" :post="props.row" />
          </div>
        </b-table-column>
      </b-table>

      <Pagination
        :total-results='posts.total'
        :total-pages='Math.ceil(posts.total / posts.per_page)'
        :per-page='posts.per_page'
        :current-page='posts.current_page'
        @pagechanged='onPageChange'
      />
      <PostPreviewModal ref='postPreview' :post='setSelectedPost' />
    </div>
  </section>
</template>

<style scoped>
.loading {
  background-color: grey;
}
</style>
<script>
import { mapActions, mapState } from 'vuex'
import PostPreviewModal from '@/components/Model/Post/PostPreviewModal'
import Pagination from '@/components/Model/Post/Pagination'
import PostsFilters from '@/components/Model/Post/PostsFilters'
import ButtonEditStatus from '@/components/UI/ButtonEditStatus'
import ButtonRemind from '@/components/UI/ButtonRemind'
import ButtonEditPriority from './ButtonEditPriority'

export default {
  name: 'TabPosts',
  props :['isLoadingPosts'],
  components: {
    PostPreviewModal,
    ButtonRemind,
    Pagination,
    PostsFilters,
    ButtonEditStatus,
    ButtonEditPriority
  },
  data: function() {
    return {
      isLoadingStatus: false,
      setSelectedPost: {},
      hover: false,
      time3: null,
      value: '',
      filters: {},
      currentPost: '',
      loading: false,
      defaultSortDirection: 'asc',
      order: ['asc', 'asc', 'asc', 'asc', 'asc']
    }
  },
  computed: {
    ...mapState('post', {
      posts: (state) => state.posts,
      config: (state) => state.postsConfig,
      post: (state) => state.post
    })
  },

  methods: {
    ...mapActions('post', ['getPosts', 'getPost', 'updateStatus']),

    showPostPreview(post) {
      this.getPost(post.id).then((result) => {
        this.setSelectedPost = result.data.post
      })
      this.$refs.postPreview.trigger()
    },
    loadAsyncData() {
      this.getPosts()
        .then(() => {
          this.loading = false
        })
        .finally(() => {
          this.loading = true
        })
    },
    loadpage() {
      this.loading = true
    },
    onSort(field) {
      let columns = ['id', 'status_id', 'title', 'created_at', 'priority']
      let i = 0
      columns.filter((column, index) => {
        if (column == field) {
          i = index
        }
      })
      if (this.order[i] == 'asc') {
        this.order[i] = 'desc'
      } else {
        this.order[i] = 'asc'
      }

      this.config.page = 1
      this.config.sort = field
      this.config.sortDirection = this.order[i]
      this.loadAsyncData()
    },
    onPageChange(page) {
      this.config.page = page
      this.loadAsyncData()
    }
  }
}
</script>
